import React from 'react';
import './css/App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Home} from "./pages/Home";
import {JoeyPersonal} from "./pages/JoeyPersonal";

function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/joey-page" element={<JoeyPersonal/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
