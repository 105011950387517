import "../css/home.css";
import {HelmetProvider} from "react-helmet-async";

export const Home = () => {
    return(
        <div id="home">
        <HelmetProvider context={{}}>
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
            <link href="https://fonts.googleapis.com/css2?family=Jost&display=swap" rel="stylesheet" />
            <title>israel.ps</title>
        </HelmetProvider>

        <div id="topSection">
            <img src="/images/logo.png" alt="israel.ps logo"/>
        </div>
        <div id="line"></div>
        <p className="quote">Through all the violence and lies can we find ourselves reaching towards a point of
            peace?</p>
        <p className="quote">"If you want peace, you don't talk to your friends. You talk to your enemies." - Desmond
            Tutu</p>
        <div id="boxesContainer">
            <div className="box">
                <p className="title-buttons">Donate to Israel</p>
                <div className="buttonContainer">
                    <a className="button white" href="https://www.fidf.org/" target="_blank" rel="noreferrer">FIDF</a>
                    <a className="button blue" href="https://www.mdais.org/" target="_blank" rel="noreferrer">MDA</a>
                    <a className="button white" href="https://www.leket.org/" target="_blank" rel="noreferrer">Leket Israel</a>
                </div>
            </div>
            <div className="verticalLine"></div>
            <div className="box">
                <p className="title-buttons">Donate to Palestine</p>
                <div className="buttonContainer">
                    <a className="button red" href="https://www.anera.org/" target="_blank" rel="noreferrer">ANERA</a>
                    <a className="button white" href="https://www.pcrf.net/" target="_blank" rel="noreferrer">PCRF</a>
                    <a className="button green" href="https://www.palestinercs.org/" target="_blank" rel="noreferrer">PRCS</a>
                </div>
            </div>
        </div>
        <div id="textLine">
            <p>Created and maintained by Joey585</p>
        </div>
        </div>
    )
}
